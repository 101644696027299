
import { defineComponent } from 'vue'

import Bag from '@/assets/svg/home-dashboard/bag.svg?inline'
import Group from '@/assets/svg/home-dashboard/group.svg?inline'
import Net from '@/assets/svg/home-dashboard/net.svg?inline'
import Win from '@/assets/svg/home-dashboard/win.svg?inline'
import Withdraw from '@/assets/svg/home-dashboard/withdraw.svg?inline'
import Have from '@/assets/svg/home-dashboard/have.svg?inline'

import MainButton from '@/components/atoms/buttons/MainButton.vue'
import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'HomeDashboardSection',
  data () {
    return {
      statistics: {},
      translateData: {},
      hasPayment: {},
      hasPaymentNew: '',
      accrualTypeIn: true,
      accrualTypeOut: false,
      accrualTypeReferral: false,
      page: 1,
      historyMeta: {},
      historyData: {}
    }
  },
  components: {
    MainButton,
    RefLink,
    Bag,
    Group,
    Net,
    Win,
    Withdraw,
    Have
  },
  mounted () {
    this.hasPayment = JSON.parse(localStorage.getItem('userData'))
    this.getDashboardStatistics()
    this.translate()
    this.hasPaymentNew = JSON.parse(localStorage.getItem('has_payments'))
    this.goToInTab()
  },
  watch: {
    hasPayment () {
      if (this.hasPayment.has_payments) {
        this.hasPaymentNew = true
      }
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('my_deposit', 'attracted_people', 'total_in_my_network', 'earnings', 'withdrawn', 'for_withdrawal', 'top_up_70_text', 'top_up_70_button')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToPayment () {
      this.$router.push('/dashboard/replenishment')
    },
    getDashboardStatistics () {
      this.$store.dispatch('getPayments/getStatistics').then(
        (response) => {
          this.statistics = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getHistory (type) {
      let url = `/${type}`
      url += `?page=${this.page}`
      this.$store.dispatch('getPayments/getHistory', url).then(
        (response) => {
          // this.historyData = response.data
          this.historyData = [{
            date: '31.10.2023',
            amount: '11'
          },
          {
            date: '31.10.2023',
            amount: '11'
          }]
          this.historyMeta = response.meta
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getProfile (data) {
      this.$store.dispatch('getPayments/getProfile', data).then(
        (response) => {
          this.isPayment = response.data.has_payments
          localStorage.setItem('has_payments', this.isPayment)
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToInTab () {
      this.page = 1
      this.getHistory('in')
      this.accrualTypeIn = true
      this.accrualTypeOut = false
      this.accrualTypeReferral = false
    },
    goToOutTab () {
      this.page = 1
      this.getHistory('out')
      this.accrualTypeIn = false
      this.accrualTypeOut = true
      this.accrualTypeReferral = false
    },
    goToReferralTab () {
      this.page = 1
      this.getHistory('referral')
      this.accrualTypeIn = false
      this.accrualTypeOut = false
      this.accrualTypeReferral = true
    }
  }
})
